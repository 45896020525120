<template>
  <modal
    name="installation-modal"
    :resizable="true"
    :height="'auto'"
    :width="'100%'"
    :scrollable="true"
  >
    <div class="row bg-secondary mt-1 ml-0">
      <!-- <h1> Installation modal </h1> -->
      <div class="col-6">
        <h5
          v-if="installation.contractor.label != 'undefined'"
          class="float-left align-middle text-white mt-2"
        >
          {{ installation.contractor.label }}
        </h5>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="float-right">
          <button
            id="editinstallationButton"
            class="btn btn-sm btn-pill btn-info my-1 mx-2"
            @click="updateinstallation(installation)"
          >
            Edytuj
          </button>
          <button
            class="btn btn-sm btn-pill btn-danger my-1 mx-2"
            @click="$emit('closeModal')"
          >
            Zamknij
          </button>
        </div>
      </div>
    </div>
    <div id="modal-content" class="row mb-5">
      <div class="col-12 p-5">
        <h3>Dane kontrahenta:</h3>
        <div class="row">
          <table class="table table-striped table-sm">
            <tbody>
              <tr>
                <td>Nazwa:</td>
                <td>
                  <b> {{ installation.contractor.label }}</b>
                </td>
              </tr>
              <tr>
                <td>Adres:</td>
                <td>
                  <b> {{ installation.contractor.address }} </b>
                </td>
              </tr>
              <tr>
                <td>Numer telefonu:</td>
                <td>
                  <b
                    ><a
                      style="text-decoration: none; color: #000; cursor: pointer"
                      :href="`tel:${installation.contractor.phoneNumber}`"
                      >{{ installation.contractor.phoneNumber }}</a
                    ></b
                  >
                </td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>
                  <b
                    ><a
                      style="text-decoration: none; color: #000; cursor: pointer"
                      :href="`mailto:${installation.contractor.email}`"
                      >{{ installation.contractor.email }}</a
                    ></b
                  >
                </td>
              </tr>
              <tr>
                <td>NIP:</td>
                <td>
                  <b> {{ installation.contractor.nip }} </b>
                </td>
              </tr>
              <tr>
                <td>Osoba Kontaktowa:</td>
                <td>
                  <b> {{ installation.contractor.contactPerson }} </b>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-12">
            <small class="mx-2">Galeria instalacji</small>

            <label style="cursor: pointer" for="customInputFile">
              <svg
                width="1.5em"
                height="1.5em"
                viewBox="0 0 16 16"
                class="bi bi-camera"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 12V6a1 1 0 0 0-1-1h-1.172a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 9.173 3H6.828a1 1 0 0 0-.707.293l-.828.828A3 3 0 0 1 3.172 5H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"
                />
                <path
                  fill-rule="evenodd"
                  d="M8 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                />
                <path d="M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
              </svg>
            </label>

            <input
              style="display: none"
              id="customInputFile"
              class="custom-file-input"
              type="file"
              accept="image/*"
              @change="uploadImage"
            />
          </div>

          <div class="col-12">
            <!-- {{consolelog}} -->

            <div
              class="d-inline-flex p-1"
              v-for="image in installation.installation.gallery"
              :key="image.id"
            >
              <a :href="image" target="_blank"
                ><img :src="image" alt="" width="100px" height="100px"
              /></a>
            </div>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-sm-12 col-md-4">
            <small>Data serwisu </small>
            <input
              v-model="installation.installation.service"
              type="date"
              class="form-control mt-1 mb-1"
              placeholder="Data serwisu"
            />
          </div>

          <div class="col-sm-12 col-md-4">
            <small>Segregator</small>
            <input
              v-model="installation.installation.binder"
              type="text"
              class="form-control mt-1 mb-1"
              placeholder="Segregator"
            />
          </div>

          <div class="col-sm-12 col-md-4">
            <small>Brygada montazowa</small>
            <input
              v-model="installation.installation.squad"
              type="text"
              class="form-control mt-1 mb-1"
              placeholder=""
            />
          </div>
        </div>

        <small>Uwagi</small>
        <vue-editor
          class="border"
          style="height: 350px; overflow: auto"
          v-model="installation.installation.content"
        ></vue-editor>
        <!-- <vue-editor
          v-else
          style="height: 350px; overflow: auto"
          v-model="installation.gówno"
        ></vue-editor> -->
        <!-- <div v-else class=border style="height:350px;overflow:auto;" v-html="installation.notice"></div> -->
        <!-- <div class="border">{{installation.installation}}</div> -->
        <!-- <textarea
          class="form-control my-1"
          v-model="installation.content"
          placeholder="Komentarz"
        /> -->
        <!-- <input class="form-control my-1" v-model="car.pt" placeholder="Przegląd techniczny" /> -->

        <!-- <div class="col-6">
        <div class="p-5">
     
        </div>
      </div> -->
        <!-- <div class="col-6">
         
      </div> -->
        <hr />
        <small @click="deleteInstallation(installation)">
          Usuń instalację tylko gdy jesteś tego pewny.
        </small>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
import { fb } from "../firebase";
export default {
  props: ["installation"],
  label: "",

  data() {
    return {
      imageUrl: "",
      consolelog: "test",
    };
  },
  components: {
    VueEditor,
  },
  computed: {
    ...mapState(["userProfile", "installationUpdated"]),
    installationGallery() {
      if (this.installation.installation.gallery) {
        return this.installation.installation.gallery;
      } else {
        return [];
      }
    },
  },

  methods: {
    updateinstallation() {
      // console.log(this.installation);
      // console.log("testowo");
      // console.log(this.installation.service);
      console.log(this.installation.installation.binder);
      if(this.installation.installation.binder == undefined ) {
        this.installation.installation.binder = "";
      }
      console.log(this.installation.installation.binder);
      this.$store
        .dispatch("updateInstallation", {
          id: this.installation.installation.id,
          address: this.installation.contractor.address,
          content: this.installation.installation.content,
          email: this.installation.contractor.email,
          service: this.installation.installation.service,
          notice: this.installation.content,
          squad: this.installation.installation.squad,
          binder: this.installation.installation.binder,
          gallery: this.installationGallery,
        })
        .then(() => {
          if (this.installationUpdated) {
            let button = document.getElementById("editinstallationButton");
            button.style.backgroundColor = "#409855";
            button.style.color = "#17801b";
            button.innerText = "Instalacja została edytowana";
          }
          setTimeout(() => {
            let button = document.getElementById("editinstallationButton");
            button.style.backgroundColor = "#409855";
            button.style.color = "";
            button.innerText = "Instalacja została edytowana";
            this.carUpdated = null;
          }, 200);
          setTimeout(() => {
            this.$emit("closeModal");
          }, 2000);
        });
    },

    deleteInstallation(installation) {
      console.log(installation.installation.id);
      if (confirm("Czy jesteś pewien ze chcesz usunąć instalację?")) {
        this.$store.dispatch("deleteInstallation", {
          id: installation.installation.id,
        });
      } else {
        setTimeout(() => {
          this.$emit("closeModal");
        }, 2000);
        console.log("installation Deleted.");
      }
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },

    uploadImage(e) {
      let file = e.target.files[0];
      Object.defineProperty(file, "name", {
        writable: true,
        value: this.uuidv4(),
      });
      //   file.name = this.uuidv4()

      fb.storage()
        .ref("installations/" + file.name)
        .put(file)
        .then((uploadTaskSnapshot) => {
          uploadTaskSnapshot.ref.getDownloadURL().then((response) => {
            console.log(response);
            this.installationGallery.push(response);
            //  this.consolelog = downloadURL
          });
        });

      // let uploadTask = storageRef.put(file);
      //     uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      //       this.task.imageUrl = downloadURL;
      //       this.disabled = 0;
      //       this.showSuccess = true;

      //       setTimeout(() => {
      //         this.showSuccess = false;
      //       }, 2000);
      //     });
    },
  },
};
</script>
<style></style>
